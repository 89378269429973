import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getIronSession } from 'iron-session';

import ironSessionOptions from '../server/session';
import LoginPageLayout from '../layouts/LoginPageLayout';
import CustomerPinIcon from '../assets/svg/login/customer-pin.svg';
import ProviderPinIcon from '../assets/svg/login/provider-pin.svg';
import { Button } from '../components/homee-ui';
import { BREAKPOINT_VALUE, COLOR, SPACING } from '../components/homee-ui/theme';
import { initStore } from '../redux/store';
import getServerFeatureFlags from '../utils/getServerFeatureFlags';
import { LoginDataTestIDs } from '../../cypress/support/actions/login/constants';
import clientConstants from '../config';

function LoginPage() {
  const router = useRouter();

  const nextQueryParam = {};
  if (router.query.next) {
    nextQueryParam.next = router.query.next;
  }
  return (
    <LoginPageLayout>
      <div className="root">
        <div className="login-row">
          <div className="option">
            <ProviderPinIcon className="pin" />
            <h2>HOMEE Pro-Portal</h2>
            <p>
              Track your success with HOMEE, view job history, and manage your
              account.
            </p>
            <a href={clientConstants.PROPORTAL_URL ?? ''}>
              <Button
                variant="primary"
                size="small"
                style={{ display: 'inline-block' }}
              >
                Log In
              </Button>
            </a>
          </div>
          <div className="verticalLine" />
          <div className="option">
            <CustomerPinIcon className="pin" />
            <h2>Employee Login</h2>
            <p>Tap “Log In” to login to your account.</p>
            <Link
              href={{
                pathname: '/login/internal',
                query: nextQueryParam,
              }}
            >
              <Button
                variant="primary"
                size="small"
                style={{ display: 'inline-block' }}
              >
                Log In
              </Button>
            </Link>
          </div>
        </div>
        <div className="verticalLine mobile-only" />
        <Link
          href={{
            pathname: '/login/internal',
            query: nextQueryParam,
          }}
          legacyBehavior
          passHref
        >
          <a
            className="internalLink"
            data-testid={LoginDataTestIDs.InternalLoginLink}
          >
            Employee Login
          </a>
        </Link>
        <div className="internalText">
          Tap “Employee Log In” to login to your account.
        </div>
      </div>
      <style jsx>
        {`
          .root {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: ${COLOR.neutral10};
            padding: ${SPACING.lg}px;
            -webkit-overflow: auto;
          }

          .login-row {
            display: flex;
            flex-direction: row;
          }

          .option {
            padding: 32px 0 72.5px 0;
            width: 50%;
          }

          .option > h2 {
            margin-top: ${SPACING.md}px;
            font-size: 40px;
            letter-spacing: 1.11px;
            line-height: 46px;
            font-weight: 800;
          }

          .option > p {
            margin: ${SPACING.xs}px 0 ${SPACING.md}px;
            font-size: 20px;
            letter-spacing: 0.42px;
            line-height: 30px;
          }

          .option > a:hover {
            text-decoration: none;
          }

          .verticalLine {
            flex-shrink: 0;
            width: 2px;
            height: 390px;
            margin: 0 ${SPACING.lg}px;
            border-left: rgba(109, 129, 144, 1) 1px solid;
          }

          .internalLink {
            margin-top: 60px;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 24px;
            font-weight: 600;
            text-decoration: underline;
          }

          .internalText {
            margin-top: ${SPACING.xxs}px;
            width: 297px;
            font-size: 13px;
            letter-spacing: 0.33px;
            line-height: 20px;
            text-align: center;
          }

          // smDown
          @media (max-width: ${BREAKPOINT_VALUE.md}px) {
            .root {
              margin: auto 0;
              justify-content: center;
              padding: ${SPACING.lg}px ${SPACING.md}px;
            }
            .pin {
              width: 37px;
              height: 45px;
            }

            .login-row {
              flex-direction: column;
              display: flex;
              width: 100%;
              margin-top: -16px;
              text-align: center;
            }

            .option {
              width: 100%;
              padding: 0;
              margin: auto;
            }

            .verticalLine {
              width: 100%;
              margin: ${SPACING.lg}px 0;
              height: 0;
              border: #969696 0.5px solid;
            }

            .internalLink {
              font-weight: 300;
              font-style: italic;
              font-size: 13px;
              margin-top: 0;
            }
          }

          // mdDown
          @media (max-width: ${BREAKPOINT_VALUE.lg}px) {
            .option > p {
              font-size: 13px;
              letter-spacing: 0.3;
              line-height: 20px;
            }
          }

          // lgDown
          @media (max-width: ${BREAKPOINT_VALUE.xl}px) {
            .option > h2 {
              font-size: 20px;
              letter-spacing: 0.42;
              line-height: 30px;
            }
          }
          @media (min-width: ${BREAKPOINT_VALUE.md}px) {
            .mobile-only {
              display: none;
            }
          }
        `}
      </style>
    </LoginPageLayout>
  );
}

/** @param {import('next').GetServerSidePropsContext} ctx */
export async function getServerSideProps(ctx) {
  // Legacy WS users will have old cookie, which we should remove or else they get header too large issues
  if (ctx.req.cookies.token_auth) {
    ctx.res.setHeader(
      'Set-Cookie',
      [
        'token_auth=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT',
        'state=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT',
      ].join('; '),
    );
  }

  const session = await getIronSession(ctx.req, ctx.res, ironSessionOptions);

  const { query } = ctx;
  const store = initStore(ctx, undefined, session);
  const userId = store.getState().user.data.id;
  const { workshop_internal_only_login } = await getServerFeatureFlags(userId);

  if (workshop_internal_only_login === 'on') {
    let destination = '/login/internal';

    if (query.next) {
      destination += `?next=${encodeURIComponent(query.next)}`;
    }

    return {
      redirect: {
        destination,
        permanent: true,
      },
    };
  }

  return {
    props: {},
  };
}

export default LoginPage;
